var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { json } from 'overmind';
import Slider from 'components/Slider.vue';
import Input from 'components/Input.vue';
import { isEqual } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
let Revenue = class Revenue extends Vue {
    constructor() {
        super(...arguments);
        this.sliderValue = json(this.stateSliderFiltersValues.revenues);
        this.radioButtonValue = this.radioButton;
        this.inputValueInitial = null;
        this.inputValueEnd = null;
    }
    setinputValueInitial(value) {
        this.inputValueInitial = Number(value) || null;
        if (this.inputValueInitial <= this.inputValueEnd) {
            this.$emit('setInputRange', {
                value_start: this.inputValueInitial,
                value_end: this.inputValueEnd,
                queryField: this.radioButtonValue,
            });
        }
    }
    setinputValueEnd(value) {
        this.inputValueEnd = Number(value) || null;
        if (this.inputValueInitial <= this.inputValueEnd) {
            this.$emit('setInputRange', {
                value_start: this.inputValueInitial,
                value_end: this.inputValueEnd,
                queryField: this.radioButtonValue,
            });
        }
    }
    setSelectedRadioButton(queryField, from) {
        this.$emit('setSelectedRadioButton', { queryField, from });
    }
    validSliderFilter(event) {
        if (event.value[0] === event.value[1]) {
            this.$emit('setErrorSliderFilter', {
                type: event.groupTagFilter,
                action: 'error',
            });
            this.$emit('setSliderFilter', event);
        }
        else {
            this.$emit('setErrorSliderFilter', {
                type: event.groupTagFilter,
                action: 'success',
            });
            if (event.value[0] !== event.value[1] && event.value[1] !== '0') {
                this.$emit('setSliderFilter', event);
            }
        }
    }
    selectedCount(filter) {
        if (filter.queryType === 'rangeSlider' &&
            filter.groupTag === 'Faturamento') {
            const isValueDefault = isEqual(this.sliderValue.value, ['0', '+500M']);
            if ((this.sliderValue.value[0] == 0 && this.sliderValue.value[1] == 0) ||
                isValueDefault) {
                return 0;
            }
            else {
                return 1;
            }
        }
    }
    getSliderStyle() {
        if (this.errorSlider) {
            return {
                color: 'red',
            };
        }
    }
    handleTrackingRevenue() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.filterName);
    }
};
__decorate([
    Prop({ default: () => { } })
], Revenue.prototype, "filter", void 0);
__decorate([
    Prop({ default: () => { } })
], Revenue.prototype, "stateSliderFiltersValues", void 0);
__decorate([
    Prop({ default: '' })
], Revenue.prototype, "filterName", void 0);
__decorate([
    Prop({ default: '' })
], Revenue.prototype, "radioButton", void 0);
__decorate([
    Prop({ default: false })
], Revenue.prototype, "errorSlider", void 0);
Revenue = __decorate([
    Component({
        components: {
            Slider,
            Input,
        },
    })
], Revenue);
export default Revenue;
