var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, PropSync, Watch, } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import IconDislike from '@/components/Icons/IconDislike.vue';
import IconTrash from '@/components/Icons/IconTrash.vue';
import IconHistory from '@/components/Icons/IconHistory.vue';
import IconBuilding from '@/components/Icons/IconBuilding.vue';
import IconSlimMenu from '@/components/Icons/IconSlimMenu.vue';
import { SearchStatus } from '@/overmind/search/state';
import { isEmpty } from 'lodash';
import { json } from 'overmind';
import SearchFilterNegativeCnpjs from './SearchFilterNegativeCnpjs.vue';
import Modal from '@/components/Modal.vue';
import Tooltip from '@/components/Tooltip.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { connect } from '@/overmind';
import IconAutoPlay from '@/components/Autoplay/icons/IconAutoPlay.vue';
let SearchFilterHeader = class SearchFilterHeader extends Vue {
    constructor() {
        super(...arguments);
        this.iconOrder = true;
        this.itemSelectedOrderBy = [];
        this.orderByItems = [];
        this.itemSelected = null;
        this.negativeCnpjsSelected = [];
        this.keyAdminNegativeCnpjs = 0;
        this.showNegatedModal = false;
        this.showButtonAutoPlay = false;
    }
    mounted() {
        //@ts-ignore
        if (this.histories.length > 0) {
            this.showButtonAutoPlay = true;
        }
    }
    onOrderItems(newVal, oldVal) {
        this.orderByItems = json(newVal);
    }
    onSelectedOrderBy(newVal, oldVal) {
        this.selectedOrderBy.find(item => {
            const res = this.orderByItems.find(orderByItem => {
                return (orderByItem.queryField === item.queryField &&
                    orderByItem.value === item.value);
            });
            const existSelectedItem = this.itemSelectedOrderBy.find(i => res && i.queryField == res.queryField);
            if (!existSelectedItem && res) {
                this.itemSelected = res;
            }
        });
    }
    showSearchHistory(verify) {
        if (verify) {
            return true;
        }
        else {
            mixpanelTracking('search: Clicou em histórico de buscas salvas');
            return true;
        }
    }
    async showConsultHistory() {
        mixpanelTracking('search: Listou empresas consultadas');
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.getConsultHistory(1);
    }
    get enabledSortOption() {
        //return this.companiesCount === 0 || this.noCredits;
        return this.noCredits;
    }
    setQuickSearchInput(term) {
        return term;
    }
    setOrderBy(value) {
        mixpanelTracking('search: Ordem do resultado foi alterada');
        this.checkItemSelected(value);
        return value;
    }
    checkItemSelected(itemSelected) {
        if (this.itemSelected == itemSelected) {
            this.itemSelected = null;
        }
        else {
            this.itemSelected = itemSelected;
        }
    }
    createSearch(companyId) {
        if (this.totalSelected !== 0 || !isEmpty(this.genericTerm)) {
            mixpanelTracking('search: Gerou uma busca');
            this.$emit('createSearch', true);
        }
    }
    showAdvancedFilterModal() {
        const { searchId } = this.$route.params;
        mixpanelTracking('filtros avançados: Abriu os filtros avançados');
        //@ts-ignore
        this.actionsSearch.showAdvancedFilterModal({
            show: true,
            searchId,
            clickedBtn: true,
        });
    }
    disabledFilterAdvancedButton() {
        return this.createSearchState === SearchStatus.RECEIVING;
    }
    clearCache() {
        mixpanelTracking('search: Limpou o cache do navegador');
        return true;
    }
    createNegativeCnpjs() {
        mixpanelTracking('search: Negativou uma lista de CNPJs');
        return this.negativeCnpjsSelected;
    }
    openNegatedModal() {
        this.showNegatedModal = true;
        mixpanelTracking('search: Clicou em CNPJs Negativados');
        this.$emit('modalNegativeCnpjs');
    }
    onChangeNegativeCnpjs(currVal) {
        this.negativeCnpjsSelected = currVal;
    }
    clearCnpjs() {
        this.negativeCnpjsSelected = [];
        this.keyAdminNegativeCnpjs += 1;
    }
    setNegativeCnpjs(cnpjs) {
        this.negativeCnpjsSelected = cnpjs;
    }
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], SearchFilterHeader.prototype, "createSearchState", void 0);
__decorate([
    Prop({ default: 0 })
], SearchFilterHeader.prototype, "totalSelected", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchFilterHeader.prototype, "negativeCnpjs", void 0);
__decorate([
    Prop({ default: false })
], SearchFilterHeader.prototype, "loadingGetCnpjs", void 0);
__decorate([
    PropSync('searchTerm', { default: '' })
], SearchFilterHeader.prototype, "genericTerm", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchFilterHeader.prototype, "orderItems", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchFilterHeader.prototype, "selectedOrderBy", void 0);
__decorate([
    Prop({ default: false })
], SearchFilterHeader.prototype, "noCredits", void 0);
__decorate([
    Prop({ default: false })
], SearchFilterHeader.prototype, "disableShowFilterButton", void 0);
__decorate([
    Prop({ default: false })
], SearchFilterHeader.prototype, "emptyReturn", void 0);
__decorate([
    Prop({ default: 0 })
], SearchFilterHeader.prototype, "companiesCount", void 0);
__decorate([
    Prop({ default: 0 })
], SearchFilterHeader.prototype, "autoplayActiveCount", void 0);
__decorate([
    Watch('orderItems', { immediate: true })
], SearchFilterHeader.prototype, "onOrderItems", null);
__decorate([
    Watch('selectedOrderBy')
], SearchFilterHeader.prototype, "onSelectedOrderBy", null);
__decorate([
    Emit('showSearchHistory')
], SearchFilterHeader.prototype, "showSearchHistory", null);
__decorate([
    Emit('setQuickSearchInput')
], SearchFilterHeader.prototype, "setQuickSearchInput", null);
__decorate([
    Emit('setOrderBy')
], SearchFilterHeader.prototype, "setOrderBy", null);
__decorate([
    Emit('clearCache')
], SearchFilterHeader.prototype, "clearCache", null);
__decorate([
    Emit('createNegativeCnpjs')
], SearchFilterHeader.prototype, "createNegativeCnpjs", null);
__decorate([
    Watch('negativeCnpjs')
], SearchFilterHeader.prototype, "onChangeNegativeCnpjs", null);
SearchFilterHeader = __decorate([
    Component(connect(({ actions, state }) => ({
        userActions: actions.users,
        actionsSearch: actions.search,
        activeModal: state.search.activeModal,
        histories: state.search.histories,
        showAutoPlayButton: state.search.showAutoPlayButton,
    }), {
        name: 'SearchFilterHeader',
        components: {
            Input,
            Modal,
            SearchFilterNegativeCnpjs,
            IconDislike,
            IconTrash,
            IconHistory,
            IconSlimMenu,
            Tooltip,
            IconBuilding,
            IconAutoPlay,
        },
    }))
], SearchFilterHeader);
export default SearchFilterHeader;
