var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import { SearchCepStatus } from '@/overmind/filters/state';
import { json } from 'overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let DistanceFromALocation = class DistanceFromALocation extends Vue {
    constructor() {
        super(...arguments);
        this.cep = this.stateSliderFilters.cep_distance.zip || '';
        this.changedCep = false;
        this.stateDistanceFilters = json(this.stateSliderFilters.cep_distance.distance);
        this.distanceValue = Number(this.stateDistanceFilters.split('km')[0]) || 10;
    }
    mounted() {
        if (this.dataCEP && this.dataCEP.cep)
            this.cep = this.dataCEP.cep || this.stateSliderFilters.cep_distance.zip;
    }
    handleDistanceFilterStatus() {
        mixpanelTracking('filtros avançados: Expandiu o grupo de filtros: ' + this.filterName);
    }
    handleDistanceValue() {
        return !this.stateDistanceFilters.match('km')
            ? this.distanceValue
            : `${this.distanceValue}km`;
    }
    get distanceInfo() {
        return {
            groupTagFilter: 'distância',
            queryField: 'cep_distance',
            distance: this.distanceValue,
            zip: this.dataCEP && this.dataCEP.cep
                ? this.dataCEP.cep
                : this.cep && this.cep.length > 8
                    ? this.cep
                    : this.stateSliderFilters.cep_distance.zip
                        ? this.stateSliderFilters.cep_distance.zip
                        : '',
        };
    }
    get dataCepSelected() {
        return json(this.dataCEP);
    }
    searchCep() {
        this.changedCep = true;
        const cepValid = (this.cep || '').replace(/[\W_]+/g, '');
        if (cepValid && cepValid.length === 8) {
            this.handleDistanceFilterStatus();
            this.$emit('searchCep', cepValid.trim(), `${this.distanceValue}km`);
        }
        if (cepValid.length === 0 || cepValid === null) {
            this.$emit('searchCep', cepValid.trim(), '');
        }
    }
    get cepStatusSearching() {
        return this.searchCepStatus === SearchCepStatus.RECEIVING;
    }
    get cepStatusError() {
        return this.searchCepStatus === SearchCepStatus.ERROR && this.cep.length > 8;
    }
    get cepStatusReceived() {
        return this.searchCepStatus === SearchCepStatus.RECEIVED;
    }
    handleMessageError() {
        if (this.searchCepStatus === SearchCepStatus.ERROR && this.cep.length > 8)
            return 'Nenhuma informação foi encontrada para este CEP';
        else if (this.dataCEP && this.dataCEP.cep && this.cep.length > 8)
            return 'Nenhuma coordenada foi encontrada para este CEP';
    }
    get canSearchSearchCep() {
        return this.cepStatusSearching;
    }
    get canShowResultsFound() {
        return !this.cepStatusError && this.cepStatusReceived && this.cep.length > 8;
    }
};
__decorate([
    Prop({ default: () => { } })
], DistanceFromALocation.prototype, "stateSliderFilters", void 0);
__decorate([
    Prop({ default: undefined })
], DistanceFromALocation.prototype, "dataCEP", void 0);
__decorate([
    Prop({ default: '' })
], DistanceFromALocation.prototype, "searchCepStatus", void 0);
__decorate([
    Prop({ default: '' })
], DistanceFromALocation.prototype, "filterName", void 0);
__decorate([
    Prop({ default: 0 })
], DistanceFromALocation.prototype, "selected", void 0);
DistanceFromALocation = __decorate([
    Component({
        name: 'DistanceFromALocation',
        components: {
            Input,
        },
    })
], DistanceFromALocation);
export default DistanceFromALocation;
