var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Emit } from 'vue-property-decorator';
import SearchFilterHeader from '../components/SearchFilterHeader.vue';
import SearchStatsHeader from '../components/SearchStatsHeader.vue';
import { connect } from '@/overmind';
import { json } from 'overmind';
import { SearchStatus } from '@/overmind/search/state';
import { isEmpty, sum } from 'lodash';
import { mixpanelTracking } from '@/services/mixpanel';
import OnboardingUpdateInfo from '@/components/SignUp/UpdateUserPreferences/OnboardingUpdateInfoContent.vue';
import SearchHistoryAutoPlayHeader from '@/components/Autoplay/SearchHistoryAutoPlayHeader.vue';
let SearchHeaderContainer = class SearchHeaderContainer extends Vue {
    constructor() {
        super(...arguments);
        this.disableShowFilterButton = true;
        this.sortOptions = {};
        this.negativeCnpjs = [];
        this.openModalUpgradeAccount = false;
        this.showExportModal = false;
        this.historyAutoPlayHeaderKey = 0;
        this.autoplayActiveCount = 0;
    }
    selectedOrderBy() {
        // @ts-ignore
        return json(this.stateFilters.selectedSort);
    }
    mounted() {
        this.handleAutoplayActiveCount();
    }
    totalSelectedFilters() {
        const selected = sum(
        // @ts-ignore
        Object.keys(this.totalSelected).map(
        // @ts-ignore
        item => this.totalSelected[item].total));
        return selected >= 0 ? selected : 1;
    }
    handleAutoplayActiveCount() {
        //@ts-ignore
        this.autoplayActiveCount = this.histories.filter(history => history.autoplay == true).length;
    }
    autoplayRemountComponent() {
        this.historyAutoPlayHeaderKey += 1;
        this.remountSearchBody();
    }
    remountSearchBody() { }
    async createSearch() {
        // @ts-ignore TS2349: property inexistent
        // this.actionsSearch.setShowCompany(false)
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchByInteraction(true);
        // @ts-ignore
        this.actionsSearch.resetCompaniesSelectedToImport();
        if (!isEmpty(this.sortOptions)) {
            // @ts-ignore TS2349: property inexistent
            await this.actionsSearch.searches.createSearch({
                sort: this.sortOptions,
            });
        }
        else {
            //@ts-ignore TS2349: property inexistent
            await this.actionsSearch.searches.createSearch({});
        }
        this.$router
            .push({
            name: 'searchId',
            // @ts-ignore TS2349: property inexistent
            params: { searchId: this.stateSearch.currentSearchId },
        })
            .catch(err => err);
    }
    async setAdvancedCharts() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowMakeDecision(false);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchByInteraction(true);
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setAdvancedCharts();
        // @ts-ignore
        this.actionsSearch.resetCompaniesSelectedToImport();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        // @ts-ignore TS2349: property inexistent
        if (this.advancedGraphics.totalDecisionMakers == 0) {
            this.$router
                .push({
                name: 'searchId',
                // @ts-ignore TS2349: property inexistent
                params: { searchId: this.stateSearch.currentSearchId },
            })
                .catch(err => err);
        }
    }
    async getModalImportInformations() {
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.getCredits();
        // @ts-ignore TS2349: property inexistent
        await this.actionsUsers.getUserMe();
        // @ts-ignore TS2349: property inexistent
        await this.actionsInfosets.fetchAllInfosets({ limit: 10, page: 1 });
    }
    async openUpdateAccountDataModal() {
        //@ts-ignore
        if (['', null].includes(this.userOrigin)) {
            this.toggleModalExport();
            return;
        }
        //@ts-ignore
        if (this.userJourney) {
            //@ts-ignore
            const isNewAccount = this.userJourney.is_new_account;
            //@ts-ignore
            const doneFirstSearch = this.userJourney.user_journey.includes('primeira_busca_realizada');
            //@ts-ignore
            const doneFirstExportation = this.userJourney.user_journey.includes('primeira_exportacao_interna_realizada');
            if (isNewAccount && !doneFirstExportation) {
                this.openModalUpgradeAccount = true;
            }
            else {
                this.openModalUpgradeAccount = false;
                this.toggleModalExport();
            }
        }
    }
    async toggleModalExport(value = true) {
        this.showExportModal = value;
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.toggleModalExport({ value });
        if (!value) {
            mixpanelTracking('Fechou exportação para lista');
            // @ts-ignore TS2349: property inexistent
            this.actionsSearch.statusTransitionExport(SearchStatus.IDLE);
        }
        else {
            mixpanelTracking('exportação interna: Abriu exportação para lista');
        }
    }
    showSearchHistory() {
        // @ts-ignore
        this.actionsSearch.toggleShowHistory();
    }
    setQuickSearchInput(term) {
        if (term) {
            // @ts-ignore
            this.actionsFilters.setInputSearchTerm(term);
            this.searchBy(term);
        }
        else {
            // @ts-ignore
            this.actionsFilters.resetMultiMatch();
            // @ts-ignore
            this.actionsFilters.resetMatchEither();
            // @ts-ignore
            this.actionsFilters.setInputSearchTerm('');
            // @ts-ignore TS2349: property inexistent
            this.actionsSearch.searches.buildPotentialResults();
        }
    }
    searchBy(term) {
        // @ts-ignore
        this.actionsFilters.resetMatchEither();
        // @ts-ignore
        this.actionsFilters.setQuickSearchInputFilter(term);
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.searches.buildPotentialResults();
        // @ts-ignore
        if (isEmpty(this.selectedSort)) {
            this.sortOptions = { key: '_score', value: 'desc' };
            // @ts-ignore
            this.actionsFilters.setOrderBy({
                queryField: this.sortOptions.key,
                value: this.sortOptions.value,
            });
        }
    }
    async setOrderBy(value) {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setSearchByInteraction(true);
        // @ts-ignore TS2349: property inexistent
        this.actionsFilters.setOrderBy(value);
        // @ts-ignore
        this.actionsSearch.resetCompaniesSelectedToImport();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        this.$router
            .push({
            name: 'searchId',
            // @ts-ignore TS2349: property inexistent
            params: { searchId: this.stateSearch.currentSearchId },
        })
            .catch(err => err);
    }
    clearCache() {
        // @ts-ignore TS2349: property inexistent
        this.userActions.clearCache();
    }
    async createNegativeCnpjs(cnpjs) {
        // @ts-ignore TS2349: property inexistent
        await this.adminActions.createNegativeCnpjs(cnpjs);
    }
    async getNegativeCnpjs() {
        // @ts-ignore TS2349: property inexistent
        this.loadingGetCnpjs = true;
        // @ts-ignore TS2349: property inexistent
        const result = await this.adminActions.getNegativeCnpjs();
        if (result.status) {
            this.negativeCnpjs = result.data.data.attributes.excluded_cnpjs;
        }
        // @ts-ignore TS2349: property inexistent
        this.loadingGetCnpjs = false;
    }
};
__decorate([
    Emit('remountSearchBody')
], SearchHeaderContainer.prototype, "remountSearchBody", null);
SearchHeaderContainer = __decorate([
    Component(connect(({ state, actions }) => ({
        stateInfosets: state.infosets,
        stateFilters: state.filters,
        stateSearch: state.search,
        actionsInfosets: actions.infosets,
        actionsFilters: actions.filters,
        actionsSearch: actions.search,
        actionsUsers: actions.users,
        credits: state.search.exportCredits,
        noCredits: state.search.noCredits,
        exclusiveOptions: state.filters.exclusiveOptions,
        stateModal: state.search.stateModal,
        infosetId: state.search.infosetId,
        searchByInteraction: state.search.searchByInteraction,
        returnSize: state.search.returnSize,
        exportType: state.search.exportType,
        companies: state.search.companies,
        emptyReturn: state.search.emptyReturn,
        selectedSort: state.filters.selectedSort,
        totalSelected: state.filters.totalSelected,
        userActions: actions.users,
        adminActions: actions.admin,
        loadingGetCnpjs: state.users.loadingGetCnpjs,
        userJourney: state.users.userJourney,
        userOrigin: state.users.origin,
        userRole: state.users.userLoggedRole,
        userEmail: state.users.currentUserEmail,
        histories: state.search.histories,
        showHistory: state.search.showHistory,
        advancedGraphics: state.search.advancedGraphics,
    }), {
        components: {
            SearchFilterHeader,
            SearchStatsHeader,
            OnboardingUpdateInfo,
            SearchHistoryAutoPlayHeader,
        },
    }))
], SearchHeaderContainer);
export default SearchHeaderContainer;
