var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import SearchHeader from '@/modules/search/containers/SearchHeader.container.vue';
import SearchBody from '@/modules/search/containers/SearchBody.container.vue';
import Alert from '@/components/Alert.vue';
import { sum } from 'lodash';
import ListExportsModal from '@/components/ListExportsPlg/ListExportsModal.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import SearchSaveModal from '@/modules/search/components/SearchSaveModal.vue';
import ContactAndSalesInfoModal from '@/components/ContactAndSalesInfoModal/ContactAndSalesInfoModal.vue';
import ReactivationAndReengagementModal from '@/components/ReactivationAndReengagementModal/ReactivationAndReengagementModal.vue';
Component.registerHooks(['beforeRouteLeave']);
let Search = class Search extends Vue {
    constructor() {
        super(...arguments);
        this.openRouteDialog = false;
        this.openSaveHistoryDialog = false;
        this.clickToConfirm = false;
        this.openExportsListModal = false;
        this.errorCreateList = false;
        this.unexpectedError = false;
        this.notEnoughChars = false;
        this.loadingCreateList = false;
        this.userJorney = {
            is_new_account: false,
            user_journey: [],
        };
        this.loadingSaveModal = null;
        this.autoplayActiveCount = 0;
        this.headerKey = 0;
        this.searchBodyKey = 0;
        this.isExitInProgress = false;
    }
    closeActiveCreateAutomaticListModal(createListAction = false) {
        if (createListAction) {
            //@ts-ignore TS2349: property inexistent
            this.actionsSearch.handleActiveModal({ modal: '' });
            return;
        }
    }
    handleAutoplayActiveCount() {
        //@ts-ignore
        this.autoplayActiveCount = this.histories.filter(history => history.autoplay == true).length;
    }
    remountHeaderKey() {
        this.headerKey += 1;
    }
    remountSearchBody() {
        this.searchBodyKey += 1;
    }
    closeModal() {
        this.hasClickedButtonConfirm();
    }
    saveHistory() {
        this.openRouteDialog = false;
        this.hasClickedButtonConfirm();
    }
    async createInfosetList(listName) {
        // @ts-ignore TS2349: property inexistent
        const cnpjsTenCompanies = this.companies.map(c => c.cnpj);
        this.loadingCreateList = true;
        if (listName.length > 0) {
            const newInfoset = {
                name: listName,
                contacts: 0,
                members: [],
                star: 'false',
            };
            // @ts-ignore TS2349: property inexistent
            const result = await this.infosetActions.createInfoset(newInfoset);
            if (result) {
                this.errorCreateList = false;
                // @ts-ignore TS2349: property inexistent
                const resExport = await this.actionsSearch.exportCompanies({
                    // @ts-ignore TS2349: property inexistent
                    searchId: this.currentId,
                    cnpjs: cnpjsTenCompanies,
                    infosetId: result,
                });
                if (resExport) {
                    // @ts-ignore TS2349: property inexistent
                    await this.userActions.addCreateListAutomaticEvent();
                    mixpanelTracking('internal export 2.0: Lista exportada com sucesso');
                    this.unexpectedError = false;
                    this.$router.push({
                        name: 'infoset',
                        params: { infosetId: result },
                    });
                    this.closeActiveCreateAutomaticListModal();
                    return;
                }
                else {
                    this.loadingCreateList = false;
                    this.unexpectedError = true;
                }
            }
            else {
                this.unexpectedError = false;
                this.errorCreateList = true;
                this.loadingCreateList = false;
            }
        }
        else {
            this.notEnoughChars = true;
            this.loadingCreateList = false;
        }
    }
    async mounted() {
        // @ts-ignore TS2349: property inexistent
        await this.userActions.getUserMe();
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searchHistory.getSearchHistory();
        // @ts-ignore TS2349: property inexistent
        await this.userActions.getCreateListAutomaticEvent();
        //@ts-ignore TS2349: property inexistent
        this.actionsSearch.handleActiveModal({ modal: '' });
        // @ts-ignore
        await this.userActions.checkUserCurrentJorney();
        // @ts-ignore
        const { searchId: id } = this.$route.params;
        // @ts-ignore
        if (!id)
            await this.filterActions.getFilters();
        // @ts-ignore
        this.actionsSearch.setSearchFromHistory(false);
        // @ts-ignore
        if (!this.saveFiltersState) {
            // @ts-ignore
            await this.filterActions.getSelectedFiltersInStorage();
        }
        const { searchId } = this.$route.params;
        if (!searchId) {
            // @ts-ignore
            await this.actionsSearch.searches.getSummary();
        }
        // @ts-ignore
        if (this.currentUser && !this.currentUser.canReactivate) {
            //@ts-ignore TS2349: property inexistent
            this.actionsSearch.showAdvancedFilterModal({
                show: true,
                plgRole: true,
                searchId: searchId,
            });
        }
        this.handleAutoplayActiveCount();
        mixpanelTracking('search: Acessou página search');
    }
    anyStateObjectToArray(obj) {
        if (obj)
            return Object.keys(obj).map(function (key) {
                return obj[key];
            });
        else
            return [];
    }
    exitWithoutSave() {
        if (this.isExitInProgress) {
            return;
        }
        this.isExitInProgress = true;
        // @ts-ignore
        this.filterActions.unsetSelectedFiltersInStorage();
        /*
        // @ts-ignore
        this.filterActions.setSaveFilters(false)*/
        this.hasClickedButtonConfirm();
        mixpanelTracking('search: Saiu e não salvou os filtros ativos durante transição de página');
        this.isExitInProgress = false;
    }
    hasClickedButtonConfirm() {
        this.clickToConfirm = true;
        this.$router.push(this.toRoute);
    }
    showNotify(message, type) {
        this.$q.notify({
            message: message,
            type: type,
        });
    }
    saveFilters() {
        // @ts-ignore
        this.filterActions.setSelectedFiltersInStorage();
        // @ts-ignore
        this.filterActions.setSaveFilters(true);
        // @ts-ignore
        this.filterActions.setChangedFilters(false);
        // this.showNotify('Seleção de filtros salva com sucesso!', 'success')
        mixpanelTracking('search: Salvou os filtros ativos durante transição de página');
        this.hasClickedButtonConfirm();
        this.openRouteDialog = false;
    }
    totalSelectedFilters() {
        const selected = sum(
        // @ts-ignore
        Object.keys(this.totalSelected).map(
        // @ts-ignore
        item => this.totalSelected[item].total));
        return selected >= 0 ? selected : 1;
    }
    async hasFilterOpen() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowMakeDecision(true);
        //@ts-ignore
        const hasInfoset = this.histories.some(h => {
            //@ts-ignore
            if (this.currentId && h.searchId == this.currentId)
                return h;
        });
        //@ts-ignore TS2349: property inexistent
        if (!hasInfoset && this.currentId.length > 0) {
            this.openRouteDialog = true;
        }
        else if (this.totalSelectedFilters() > 1) {
            this.openRouteDialog = false;
        }
    }
    async beforeRouteLeave(to, from, next) {
        // eslint-disable-next-line no-constant-condition
        if (to.name === 'searchId' || !this.expiredDateInStorage) {
            next();
        }
        else {
            //@ts-ignore
            this.actionsSearch.handleActiveModal({ modal: '' });
            //@ts-ignore
            this.actionsSearch.clearActiveStatesModal();
            await this.hasFilterOpen();
            if (this.openRouteDialog) {
                this.toRoute = to;
                if (this.clickToConfirm) {
                    next();
                }
                else {
                    next(false);
                }
            }
            else {
                next();
            }
        }
    }
    get expiredDateInStorage() {
        const storageDateString = localStorage.getItem('notShowSaveSearch');
        if (!storageDateString)
            return true;
        const storageDate = new Date(storageDateString);
        const today = new Date();
        return today > storageDate;
    }
};
Search = __decorate([
    Component(connect(({ state, actions }) => ({
        infosetActions: actions.infosets,
        actionsSearch: actions.search,
        userActions: actions.users,
        filterActions: actions.filters,
        actionsCompanies: actions.companies,
        noCredits: state.search.noCredits,
        creditsBasic: state.search.creditsBasic,
        currentId: state.search.currentSearchId,
        summary: state.search.summary,
        searchFilters: state.search.query.search,
        totalSelected: state.filters.totalSelected,
        searchFromHistory: state.search.searchFromHistory,
        saveFiltersState: state.filters.saveFilters,
        changedFilters: state.filters.changedFilters,
        companies: state.search.companies,
        accountCredits: state.users.accountCredits,
        showCreateAutomaticExportList: state.search.showCreateAutomaticExportList,
        activeModal: state.search.activeModal,
        resultCalcute: state.search.resultCalcute,
        histories: state.search.histories,
        advancedGraphics: state.search.advancedGraphics,
        currentUser: state.users.currentUser,
    }), {
        name: 'Search',
        components: {
            SearchHeader,
            SearchBody,
            Alert,
            ListExportsModal,
            SearchSaveModal,
            ContactAndSalesInfoModal,
            ReactivationAndReengagementModal,
        },
    }))
], Search);
export default Search;
