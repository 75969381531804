var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-expansion-item',{staticClass:"border-bottom",attrs:{"group":"porte","expand-separator":"","expand-icon-class":"q-pr-none","header-class":"text-grey-7"},on:{"show":_vm.handleTrackingEmployees},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row full-width items-center"},[_c('span',{staticClass:"q-mr-sm font-12"},[_vm._v("\n        "+_vm._s(_vm.filterName)+"\n      ")]),_c('span',{staticClass:"font-12 text-primary text-weight-bold",domProps:{"innerHTML":_vm._s(_vm.selectedInfo)}}),(_vm.hasError)?_c('q-icon',{staticClass:"q-pl-sm",attrs:{"color":"red-6","name":"icon-info-new","size":"14px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v("\n            Este filtro não está selecionado corretamente! "),_c('br'),_vm._v("\n            Corrija seus valores ou resete o filtro.\n          ")])])],1):_vm._e()],1)]},proxy:true}])},[(_vm.filter.queryType === 'rangeSlider')?_c('div',{staticClass:"col q-pb-sm"},[_c('q-card-section',{staticClass:"q-px-none q-pa-md q-pb-xl q-pt-lg",attrs:{"data-cy":"search-advanced-filters-card-range-employees"}},[(_vm.filter.groupTag === 'Funcionários')?[(_vm.errorSlider && !_vm.showOptionInput)?_c('p',{staticClass:"text-red absolute",staticStyle:{"top":"-20px"}},[_vm._v("\n          Faixa de valores inválida!\n        ")]):_vm._e(),(!_vm.showOptionInput)?_c('Slider',{staticClass:"q-mx-md",attrs:{"value":_vm.sliderValue.value,"data":_vm.sliderValue.label,"marks":true,"adsorb":true,"railStyle":_vm.getSliderStyle(),"dotStyle":_vm.getSliderStyle(),"labelActiveStyle":_vm.getSliderStyle(),"data-cy":"search-advanced-filters-range-employees"},on:{"update:value":function($event){return _vm.$set(_vm.sliderValue, "value", $event)},"change":function($event){return _vm.validSliderFilter({
              groupTagFilter: _vm.filter.groupTag,
              queryField: _vm.filter.queryField,
              value: $event,
            })}}}):_vm._e(),(_vm.showOptionInput)?_c('div',{staticClass:"filter-employees-range-input row justify-between items-center q-pa-none"},[_c('div',{staticClass:"col-5"},[_c('Input',{ref:"employeeInputInitial",staticClass:"filter-employees-range-input-initial-value",attrs:{"dense":"","type":"number","placeholder":"Valor inicial","data-cy":"search-filter-employees-range-input-initial-value","min":"1","inputmode":"numeric","pattern":"[0-9]*","debounceTime":500,"value":_vm.inputValueInitial,"rules":[
                function (val) { return val <= _vm.inputValueEnd ||
                  'Este valor deve ser menor ou igual que o valor final'; },
                function (val) { return val > 0 || 'Este campo deve ser maior que zero!'; } ]},on:{"update:value":function($event){_vm.setInputValueInitial(
                  $event.replace(/-/g, '').replace(/\D/g, '')
                )}}})],1),_c('div',{staticClass:"col-2 text-center full-height"},[_c('span',[_vm._v("até")])]),_c('div',{staticClass:"col-5"},[_c('Input',{ref:"employeeInputEnd",staticClass:"filter-employees-range-input-end-value",attrs:{"dense":"","type":"number","placeholder":"Valor final","data-cy":"search-filter-employees-range-input-end-value","min":"1","inputmode":"numeric","pattern":"[0-9]*","debounceTime":500,"value":_vm.inputValueEnd,"rules":[
                function (val) { return val >= _vm.inputValueInitial ||
                  'Preencha este campo com um valor maior ou igual que o valor inicial!'; } ]},on:{"update:value":function($event){_vm.setInputValueEnd($event.replace(/-/g, '').replace(/\D/g, ''))}}})],1)]):_vm._e()]:_vm._e()],2),_c('q-card-section',{staticClass:"q-pb-none q-pt-none row items-end"},[_c('q-btn',{attrs:{"flat":"","no-caps":"","text-color":"primary","rounded":"","dense":"","icon":"add_link","data-cy":"search-filter-employees-toggle-slider-input","label":!_vm.showOptionInput
            ? 'Inserir valores precisos'
            : 'Inserir intervalo de valores'},on:{"click":_vm.toggleMode}})],1)],1):_vm._e(),_c('q-card',[_c('q-card-section',{staticClass:"q-pb-xs q-pt-none",attrs:{"data-cy":"search-advanced-filters-card-sliders-employees"}},[_c('div',{staticClass:"column"},[(
            _vm.filter.typeOptions.length > 0 &&
            _vm.filter.groupTag === 'Funcionários'
          )?_c('div',{staticClass:"col border-top"},[_c('p',{staticClass:"q-ml-sm q-mb-none q-mt-sm"},[_vm._v("Escolha o tipo:")]),_vm._l((_vm.filter.typeOptions),function(typeOption,index){return _c('q-radio',{key:index,class:{ hidden: index > 1 },attrs:{"val":typeOption.queryField,"label":typeOption.label},on:{"input":function($event){return _vm.setSelectedRadioButton(typeOption.queryField, 'employees')}},model:{value:(_vm.radioButtonValue),callback:function ($$v) {_vm.radioButtonValue=$$v},expression:"radioButtonValue"}})})],2):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }