var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SearchStatus } from '@/overmind/search/state';
import Modal from 'components/Modal.vue';
import Input from 'components/Input.vue';
import { json } from 'overmind';
import { Component, Emit, Prop, Vue, Watch, } from 'vue-property-decorator';
import SettingsAutoplayModal from 'src/components/Autoplay/SettingsAutoPlayModal.vue';
import SearchExportModalHeader from '@/modules/search/components/SearchExportModal/SearchExportModalHeader.vue';
import SearchExportModalStats from '@/modules/search/components/SearchExportModal/SearchExportModalStats.vue';
import SearchExportModalFilters from '@/modules/search/components/SearchExportModal/SearchExportModalFilters.vue';
import SearchExportModalInfosetList from '@/modules/search/components/SearchExportModal/SearchExportModalInfosetList.vue';
import IconBlueSuccess from '@/components/Icons/icon-blue-success.vue';
import OnboardingUpdateInfo from '@/components/SignUp/UpdateUserPreferences/OnboardingUpdateInfoContent.vue';
import { mixpanelIncrement, mixpanelTracking } from '@/services/mixpanel';
import { connect } from '@/overmind';
let SearchExportModal = class SearchExportModal extends Vue {
    constructor() {
        super(...arguments);
        this.credit = {};
        this.showAvaliable = false;
        this.showSettingsAutoplay = false;
        this.valueExport = this.selectedCompanies.length || json(this.returnSize);
        this.infosetId = '';
        this.infosetName = '';
        this.disabledSubmit = false;
        this.searchTerm = '';
        this.name = '';
        this.currentInfosetPage = 1;
        this.loadingInfosetItems = false;
        this.openModalUpgradeAccount = false;
        this.currentWidth = 584;
        this.currentHeight = 540;
        this.percentage = 0;
        this.crm = ['pipedrive', 'rdstation', 'hubspot'];
        this.crms = [];
        this.result = {};
        this.valueSelectedCompanies = 0;
        this.history = {
            id: '',
            searchId: '',
            name: '',
            totalResults: 0,
            totalEmployees: 0,
            selected_filters: 0,
        };
        this.totalSelected = 0;
        this.activeAutoPlay = false;
    }
    async SettingsAutoplayModal() {
        //@ts-ignore
        this.showAvaliable = await this.actions.getShowAvaliableAutoplay();
        this.showSettingsAutoplay = true;
    }
    closeSettingsAutoplayModal(closeModal) {
        this.showSettingsAutoplay = false;
    }
    mounted() {
        // @ts-ignore
        this.overmind.reaction(({ search }) => search.infosetId, (infoset_id) => {
            if (infoset_id)
                this.infosetId = infoset_id;
        });
        //@ts-ignore
        if (this.showAutoPlayButton) {
            this.currentHeight = 600;
        }
    }
    updateModalSize(val) {
        if (val) {
            this.currentWidth = 0;
            this.currentHeight = 0;
        }
        else {
            this.currentWidth = 584;
            this.currentHeight = 540;
        }
    }
    onInfosetsChange(newInfosets) {
        this.loadingInfosetItems = false;
    }
    toggleAutoplayActive() { }
    handleDisabledButton() {
        const isValid = /^[a-zA-Z0-9\s/\\|\-]+$/.test(this.infosetName.trim());
        return (this.exportState === 'receiving' ||
            this.exportState === 'received' ||
            (this.infosetId === '' && this.infosetName === '' && !isValid) ||
            this.valueExport <= 0 ||
            this.valueExport > this.credits.remaining ||
            this.credits.remaining <= 0 ||
            this.disabledSubmit);
    }
    async getUserHasUpdatedAccount() {
        //@ts-ignore
        if (this.userJourney) {
            //@ts-ignore
            const isNewAccount = this.userJourney.is_new_account;
            //@ts-ignore
            const doneFirstSearch = this.userJourney.user_journey.includes('primeira_busca_realizada');
            //@ts-ignore
            const doneFirstExportation = this.userJourney.user_journey.includes('primeira_exportacao_interna_realizada');
            if (isNewAccount && !doneFirstExportation) {
                this.openModalUpgradeAccount = true;
            }
            else {
                this.newSearchExport();
            }
        }
    }
    async newSearchExport() {
        mixpanelTracking('exportação interna: Iniciou uma exportação para lista');
        mixpanelIncrement('Exportação interna');
        const data = {
            valueExport: this.valueExport,
            infosetId: this.infosetId,
            infosetName: this.infosetName,
            autoplayActive: this.activeAutoPlay,
        };
        //@ts-ignore
        await this.actions.checkUserCurrentJorney();
        this.$emit('getSearchExport', data);
    }
    async getSearchExport() {
        this.disabledSubmit = true;
        await this.createSearchHistory();
        //@ts-ignore
        if (this.activeAutoPlay) {
            this.SettingsAutoplayModal();
        }
        this.getUserHasUpdatedAccount();
    }
    toggleExclusiveOption() {
        return this.exclusiveOption.index;
    }
    closeExportModal() {
        return false;
    }
    setReturnSize(returnSize) {
        this.valueExport = Number(returnSize);
        this.valueSelectedCompanies = this.valueExport;
        return this.valueExport;
    }
    searchInfosets(term) {
        this.infosetId = '';
        return term;
    }
    getNextInfosetPage(page, searchTerm) {
        return {
            page,
            searchTerm,
        };
    }
    createInfoset(infosetName, autoplayActive) {
        this.disabledSubmit = true;
        this.infosetName = infosetName;
        if (this.infosetName && this.valueExport <= this.credits.remaining) {
            this.disabledSubmit = false;
        }
    }
    async onExportStateChanged() {
        if (this.exportState === 'received') {
            mixpanelTracking('exportação interna: Exportação para lista finalizada');
            this.getUserCreditsPercentage();
        }
        if (this.exportState === 'receiving' && this.percentage !== 100) {
            this.interval = setInterval(async () => {
                await this.getProgress();
            }, 5000);
        }
        else if (this.interval) {
            clearInterval(this.interval);
        }
    }
    async getProgress() {
        const infoset_id = this.infosetId;
        if ([null, ''].includes(infoset_id)) {
            this.percentage = 0;
        }
        else {
            // @ts-ignore
            const exportProgress = await this.infosetActions.getProgress(infoset_id);
            if (exportProgress && exportProgress.data) {
                this.percentage = exportProgress.data.progress_percentage || 0;
            }
        }
    }
    async getUserCreditsPercentage() {
        //@ts-ignore
        const data = await this.actions.getUserCreditsPercentage();
        //@ts-ignore
        await this.actions.updateAccountCredits(data);
    }
    openListAfterExport() {
        mixpanelTracking('exportação interna: Abriu a lista');
    }
    selectListItem(infosetId) {
        if (this.infosets.filter(f => f.id == infosetId).length) {
            this.name = this.infosets.filter(f => f.id == infosetId)[0].name;
        }
        else
            this.name = '';
        this.infosetId = infosetId;
        if (this.infosetId && this.valueExport <= this.credits.remaining)
            this.disabledSubmit = false;
    }
    async createSearchHistory() {
        // @ts-ignore TS2349: property inexistent
        this.actionsSearch.setShowMakeDecision(true);
        // @ts-ignore TS2349: property inexistent
        await this.actionsSearch.searches.createSearch({});
        //@ts-ignore
        this.totalSelected = Object.values(this.filterState).reduce((acc, obj) => {
            if (obj && typeof obj === 'object' && 'total' in obj) {
                //@ts-ignore
                return acc + obj.total;
            }
            return acc;
        }, 0);
        //@ts-ignore
        if (this.activeAutoPlay) {
            //@ts-ignore
            const nameHistory = this.name
                ? this.name
                : 'AutoPlay - ' + this.infosetName;
            this.infosetName = nameHistory;
            // @ts-ignore TS2349: property inexistent
            if (this.currentId == '') {
                // @ts-ignore TS2349: property inexistent
                this.showNotify('Antes de salvar uma nova busca, certifique-se de já ter realizado uma busca!!', 'warning');
            }
            else if (!!nameHistory && typeof nameHistory === 'string') {
                const newSearchHistory = {
                    name: nameHistory,
                    // @ts-ignore TS2349: property inexistent
                    totalResults: parseInt(this.summary.totalResult),
                    // @ts-ignore TS2349: property inexistent
                    totalEmployees: parseInt(
                    //@ts-ignore
                    this.advancedGraphics.totalDecisionMakers),
                    // @ts-ignore TS2349: property inexistent
                    searchId: this.currentId,
                    //@ts-ignore
                    selected_filters: this.totalSelected,
                };
                // @ts-ignore TS2349: property inexistent
                const historys = 
                //@ts-ignore
                await this.actionsSearch.searchHistory.createSearchHistory(newSearchHistory);
                //@ts-ignore
                this.history.id = historys.data.data.id;
                this.history.name = historys.data.data.attributes.name;
                this.history.searchId = historys.data.data.attributes.search_id.$oid;
                this.history.totalResults = historys.data.data.attributes.total_results;
                this.history.totalEmployees =
                    historys.data.data.attributes.total_decisors;
                this.history.selected_filters = this.totalSelected;
                // @ts-ignore TS2349: property inexistent
                this.actionsSearch.setSearchFromHistory(true);
                // @ts-ignore TS2349: property inexistent
                this.actionsSearch.setShowMakeDecision(false);
            }
        }
    }
    async onCreateInfoset(infosetName) {
        this.createInfoset(infosetName, this.activeAutoPlay);
    }
    toggleExclusiveOptionTwo(activeAutoPlay) {
        mixpanelTracking(activeAutoPlay
            ? 'exportação interna: AutoPlay está ativado'
            : 'exportação interna: AutoPlay está desativado');
        this.activeAutoPlay = activeAutoPlay;
    }
};
__decorate([
    Prop({ default: SearchStatus.NOT_RECEIVED })
], SearchExportModal.prototype, "exportState", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchExportModal.prototype, "infosets", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchExportModal.prototype, "selectedCompanies", void 0);
__decorate([
    Prop({ default: { value: 0, remaining: 0 } })
], SearchExportModal.prototype, "credits", void 0);
__decorate([
    Prop({ default: '' })
], SearchExportModal.prototype, "currentInfosetId", void 0);
__decorate([
    Prop({
        default: () => { },
    })
], SearchExportModal.prototype, "exclusiveOption", void 0);
__decorate([
    Prop({ default: false })
], SearchExportModal.prototype, "stateModal", void 0);
__decorate([
    Prop({ default: '' })
], SearchExportModal.prototype, "exportType", void 0);
__decorate([
    Prop({ default: 0 })
], SearchExportModal.prototype, "returnSize", void 0);
__decorate([
    Prop({ default: 0 })
], SearchExportModal.prototype, "exportedCompanies", void 0);
__decorate([
    Watch('openModalUpgradeAccount')
], SearchExportModal.prototype, "updateModalSize", null);
__decorate([
    Watch('infosets')
], SearchExportModal.prototype, "onInfosetsChange", null);
__decorate([
    Emit('toggleExclusiveOption')
], SearchExportModal.prototype, "toggleExclusiveOption", null);
__decorate([
    Emit('closeExportModal')
], SearchExportModal.prototype, "closeExportModal", null);
__decorate([
    Emit('setReturnSize')
], SearchExportModal.prototype, "setReturnSize", null);
__decorate([
    Emit('searchInfosets')
], SearchExportModal.prototype, "searchInfosets", null);
__decorate([
    Emit('getNextInfosetPage')
], SearchExportModal.prototype, "getNextInfosetPage", null);
__decorate([
    Watch('infosetName')
], SearchExportModal.prototype, "createInfoset", null);
__decorate([
    Watch('exportState')
], SearchExportModal.prototype, "onExportStateChanged", null);
SearchExportModal = __decorate([
    Component(connect(({ state, actions }) => ({
        histories: state.search.histories,
        actions: actions.users,
        infosetActions: actions.infosets,
        userJourney: state.users.userJourney,
        actionsSearch: actions.search,
        search: state.search,
        currentId: state.search.currentSearchId,
        summary: state.search.summary,
        accountCredits: state.users.accountCredits,
        showAutoPlayButton: state.search.showAutoPlayButton,
        advancedGraphics: state.search.advancedGraphics,
        filterState: state.filters.totalSelected,
    }), {
        components: {
            Modal,
            Input,
            SearchExportModalHeader,
            SearchExportModalStats,
            SearchExportModalFilters,
            SearchExportModalInfosetList,
            IconBlueSuccess,
            OnboardingUpdateInfo,
            SettingsAutoplayModal,
        },
    }))
], SearchExportModal);
export default SearchExportModal;
