var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-list',_vm._l((_vm.filteredFilters.filter(
      function (item) { return item.groupTag !== 'Comércio Exterior' &&
        item.groupTag !== 'company_products_services' &&
        !(item.groupTag === 'Termos customizados' && _vm.accountType === 'free'); }
    )),function(filter,filterIndex){return (_vm.loaded)?_c('q-expansion-item',{key:filterIndex,staticClass:"border-bottom",attrs:{"data-cy":"search-advanced-filters-activity-body","group":"activity","special":!_vm.canSearch ? true : false,"expand-separator":"","expand-icon-class":"q-pr-none","default-opened":filter.groupTag === 'setores e cnaes' && _vm.accountType === 'free'},on:{"before-hide":function($event){return _vm.onHideFilter(filterIndex)},"show":function($event){return _vm.onShowFilter(filterIndex, filter.title)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row full-width items-center"},[_c('span',{class:filter.special == true
              ? 'special_filter q-mr-sm font-12 text-grey-7'
              : 'q-mr-sm font-12 text-grey-7',attrs:{"data-cy":"search-advanced-filters-activity-body-filter-title"}},[_vm._v("\n          "+_vm._s(filter.title)+"\n        ")]),_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[(filter.special && !_vm.canSearch)?_c('q-icon',{staticStyle:{"margin-right":"4px","width":"16px","height":"16px"},attrs:{"name":"icon-locked","color":"grey-6","size":"12px"}}):_vm._e()],1),(
            _vm.selectCountGPT(filter) == 1 &&
            filter.title == 'Produtos ou serviços'
          )?_c('span',{staticClass:"text-primary text-weight-bold font-12 q-pr-xs"},[_vm._v("\n          ("+_vm._s(_vm.selectCountGPT(filter))+" selecionado)\n        ")]):_vm._e(),(
            _vm.selectCountGPT(filter) > 1 &&
            filter.title == 'Produtos ou serviços'
          )?_c('span',{staticClass:"text-primary text-weight-bold font-12 q-pr-xs"},[_vm._v("\n          ("+_vm._s(_vm.selectCountGPT(filter))+" selecionados)  \n        ")]):_vm._e(),(
            _vm.selectedCount(filter) > 1 && filter.title == 'Termos customizados'
          )?_c('span',{staticClass:"text-primary text-weight-bold text-weight-bold font-12 q-pr-xs"},[_vm._v("\n          ("+_vm._s(_vm.selectedCount(filter))+" selecionados)\n        ")]):_vm._e(),(
            _vm.selectedCount(filter) == 1 &&
            filter.title == 'Termos customizados'
          )?_c('span',{staticClass:"text-primary text-weight-bold text-weight-bold font-12 q-pr-xs"},[_vm._v("\n          ("+_vm._s(_vm.selectedCount(filter))+" selecionado)\n        ")]):_vm._e(),(filter.title === 'Termos customizados' || filter.info)?_c('q-icon',{attrs:{"name":"icon-info-new","color":"grey-6","size":"14px"}},[(filter.info)?_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v(" "+_vm._s(filter.info)+" ")])]):_vm._e()],1):_vm._e()],1)]},proxy:true}],null,true)},[_c('q-card',[_c('q-card-section',{staticClass:"q-pb-xs q-pt-sm"},[_c('div',{staticClass:"column"},[(
              ((_vm.fullTextTerm === '' || _vm.fullTextTerm == null) &&
                filter.filterOptions.length > 5) ||
              _vm.searchTerm
            )?_c('div',{staticClass:"col"},[_c('Input',{ref:"searchInput",refInFor:true,attrs:{"data-cy":"search-advanced-filters-activity-body-inputs","dense":"","rounded":"","outlined":"","placeholder":filter.groupTag === 'setores e cnaes'
                  ? 'Pesquise por números de CNAEs ou sua descrição'
                  : 'Pesquise aqui...',"debounceTime":200},on:{"input":function($event){return _vm.onSearchFilters(filter, $event)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e(),(filter.queryType === 'multiSelect')?_c('div',{staticClass:"col"},[(_vm.getTerms().length > 1)?_c('div',[_c('q-checkbox',{attrs:{"data-cy":"search-advanced-filters-activity-body-checkbox-select-all","label":"Selecionar todos"},on:{"input":_vm.selectAllFilterOptions},model:{value:(_vm.selectedAll),callback:function ($$v) {_vm.selectedAll=$$v},expression:"selectedAll"}})],1):_vm._e(),_c('q-virtual-scroll',{staticClass:"overflow-auto q-mt-sm",staticStyle:{"max-height":"240px"},attrs:{"items":_vm.sortItemsBySelectedState(filter.filterOptions),"virtual-scroll-slice-size":10,"virtual-scroll-item-size":40},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [_c('div',{key:index,staticClass:"col"},[_c('div',{staticClass:"row fit"},[_c('div',{staticClass:"col-auto"},[_c('q-checkbox',{attrs:{"data-cy":"search-advanced-filters-activity-body-checkboxs","label":item.label,"value":item.isSelected,"disable":_vm.radioButtonState === 'receiving'},on:{"input":function($event){return _vm.setCheckbox(
                            _vm.filterGroupId,
                            filter.id,
                            filterIndex,
                            index,
                            $event,
                            item.value
                          )}}}),(item.info)?_c('span',{staticClass:"q-ml-sm"},[_c('q-icon',{staticStyle:{"cursor":"pointer"},attrs:{"name":"icon-info-new","color":"grey-6","size":"14px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v(_vm._s(item.info))])])],1)],1):_vm._e()],1),_c('div',{staticClass:"col-grow self-center q-ml-md text-weight-thin text-grey-6"},[_c('span',{staticClass:"text-caption full-height"},[_vm._v(_vm._s(item.subline))])])])])]}}],null,true)}),_c('transition',{attrs:{"appear":"","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[(!_vm.canSearch)?_c('AdvancedFiltersModalUnlockFilter'):_vm._e()],1)],1):_vm._e(),(filter.queryType === 'multiText')?_c('div',{staticClass:"col",on:{"click":function($event){$event.stopPropagation();},"keypress":function($event){$event.stopPropagation();}}},[_c('q-select',{attrs:{"id":"select-term-custom","data-cy":"search-advanced-filters-activity-custom-term","new-value-mode":"add-unique","bg-color":"grey-2","color":"grey-2","label-color":"grey-7","use-input":"","use-chips":"","borderless":"","outlined":"","rounded":"","multiple":"","hide-dropdown-icon":"","input-debounce":"0","label":"Pressione enter para adicionar"},on:{"add":function($event){return _vm.setSelectedFilterOracle($event, 'add')},"remove":function($event){return _vm.setSelectedFilterOracle($event, 'remove')}},scopedSlots:_vm._u([{key:"selected-item",fn:function(scoped){return [_c('q-chip',{attrs:{"removable":"","color":"primary","text-color":"white"},on:{"remove":function($event){return scoped.removeAtIndex(scoped.index)}}},[_vm._v("\n                  "+_vm._s(scoped.opt)+"\n                ")])]}}],null,true),model:{value:(_vm.valueFilterOracle),callback:function ($$v) {_vm.valueFilterOracle=$$v},expression:"valueFilterOracle"}})],1):_vm._e(),(
              _vm.hasCnaeTypeFilterSelected(filter) && _vm.selectedCount(filter) > 0
            )?_c('div',{staticClass:"col border-top"},[_c('p',{staticClass:"q-ml-sm q-mb-none q-mt-sm"},[_vm._v("Tipo do CNAE:")]),_c('div',_vm._l((filter.typeOptions),function(typeOption,index){return _c('q-radio',{key:index,attrs:{"val":typeOption.queryField,"label":typeOption.label,"data-cy":"search-advanced-filters-activity-body-radio-button"},on:{"input":function($event){return _vm.setSelectedRadioButton(typeOption.queryField, 'cnae')}},model:{value:(_vm.radioButtonValue),callback:function ($$v) {_vm.radioButtonValue=$$v},expression:"radioButtonValue"}},[(index === 3)?_c('q-icon',{staticStyle:{"cursor":"pointer"},attrs:{"name":"icon-info","color":"grey-6","size":"18px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v(_vm._s(typeOption.info))])])],1):_vm._e()],1)}),1)]):_vm._e()])])],1)],1):_vm._e()}),1)}
var staticRenderFns = []

export { render, staticRenderFns }